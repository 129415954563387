<template>
  <window-default ref="window" :resolve="find" :mock="mock" :proccessData="proccessData" :props="props" v-slot="{data}"
                  @loaded="(obj) => model = obj" @save="save">
    <window-body class="wrapper-lg">
      <e-row mr>
        <e-col size="1">
          <erp-s-field
              label="ID:"
          >
            <erp-input v-model="model.id" size="2" readonly />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Nome:"
          >
            <erp-input v-model="model.nome" autofocus size="2" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Descricao:"
          >
            <erp-input v-model="model.descricao" autofocus size="2" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Ativo:"
          >
            <erp-checkbox v-model="model.active" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Obrigatório:"
          >
            <erp-checkbox v-model="model.obrigatorio" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Ordem de visualização:"
          >
            <erp-input v-model="model.order" type="number" size="2" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col class="font-10 text-blue-grey-6 m-t-n-xs">
          Marque abaixo para qual tipo de cadastro estes documentos serão solicitados:
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Tipos:"
          >
            <erp-select v-model="model.tipoPessoa" required :options="tiposFiltered" size="2" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row v-if="model.file" mr>
        <e-col>
          <erp-s-field
              label="Arquivo atual:"
          >
            <a :href="model.file.url" target="_blank">{{model.file.url}}</a>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              view="tl"
              label="Gerar Documento PDF:"
          >
            <documento-select placeholder="Nenhum" @abrirDoc="abrirDoc" code="documentos-leilao-recibos"
                              v-model="model.config.document"/>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row mr>
        <e-col>
          <erp-s-field
              label="Arquivo para download:"
          >
            <input type="file" id="file"
                   v-on:change="selectFile" />
          </erp-s-field>
        </e-col>
      </e-row>

      <div class="docs-main">
        <documento ref="docApp"/>
      </div>
    </window-body>
  </window-default>
</template>

<script>
// import {UUploader} from 'uloc-vue'
import {WindowBody, ErpSField, ErpInput, ErpCheckbox, ErpSelect} from 'uloc-vue-plugin-erp'
import WindowDefault from '../../../../../reuse/window/Default'
import {
  findDocumentoRequeridoArrematante as find,
  newDocumentoRequeridoArrematante as _new,
  updateDocumentoRequeridoArrematante as update,
  uploadDocumentoRequerido
} from '../../../../../domain/cadastro/services'
import ERow from '../../../../layout/components/Row'
import ECol from '../../../../layout/components/Col'
// import save from '../../../helpers/save'
import {treeCheck} from '../../tiposbem/helpers/treeCheck'
import {fileToBase64} from '@/utils/fileToBase64'
import DocumentoSelect from "components/documentos/components/include/DocumentoSelect"
import Documento from "components/documentos/components/Documento"

let mock = {
  id: null,
  codigo: null,
  nome: null,
  descricao: null,
  obrigatorio: true,
  active: true,
  tipoPessoa: 0,
  order: 0,
  file: null,
  config: {}
}

export default {
  name: 'Window',
  components: {
    DocumentoSelect,
    ECol,
    ERow,
    WindowDefault,
    WindowBody,
    ErpSField,
    ErpInput,
    ErpCheckbox,
    ErpSelect,
    Documento
  },
  props: ['props'],
  data () {
    return {
      model: JSON.parse(JSON.stringify(mock))
    }
  },
  mounted () {
  },
  computed: {
    find () {
      return find
    },
    mock () {
      return mock
    },
    tiposFiltered () {
      return [
        {
          label: 'Todos',
          value: 0
        },
        {
          label: 'Pessoa Física',
          value: 1
        },
        {
          label: 'Pessoa Jurídica',
          value: 2
        }
      ]
    }
  },
  methods: {
    update: update,
    new: _new,
    save () {
      let data = this.model
      if (this.beforeSave) {
        data = this.beforeSave(data)
      }
      if (data.config) {
        data.config = JSON.stringify(data.config)
      }
      this.$refs.window.startLoading()
      let method = this.model.id ? this.update(this.model.id, data) : this.new(data)
      method
        .then(({data}) => {
          this.model = data
          const cbSuccess = () => {
            this.$refs.window.setSuccess(true)
            this.$nextTick(() => {
              this.$refs.window.startTimerToClose()
              this.$uloc.window.emit(this.$root.wid, 'update', this.props)
            })
          }
          if (this.$file) {
            // need to upload file
            uploadDocumentoRequerido(this.model.id, {data: this.$file, filename: 'arquivo'})
              .then(({data}) => {
                delete this.$file
                cbSuccess()
              })
              .catch((error) => {
                alert('Impossível enviar o arquivo, mas os demais dados foram processados com sucesso!')
                console.error(error)
                cbSuccess()
              })
          } else {
            cbSuccess()
          }
        })
        .catch(error => {
          this.success = false
          this.errorMessage = error.data.message
          // this.errors = this.systemError(error)
          this.$nextTick(() => {
            this.$refs.window.stopLoading()
            this.alertApiError(error)
            // this.error = true
          })
        })
    },
    proccessData (data) {
      let _mock = JSON.parse(JSON.stringify(this.mock))
      data = Object.assign(_mock, data)

      if (data.tiposBem && Array.isArray(data.tiposBem)) {
        data.tiposBem = data.tiposBem.map(tipo => {
          return tipo.id
        })
      }
      if (!data.config || Array.isArray(data.config)) {
        data.config = {}
      }
      console.log(data)
      return data
    },
    treeCheck: treeCheck,
    selectFile (e) {
      if (e.target.files[0]) {
        this.$file = e.target.files[0];
        fileToBase64(this.$file)
          .then(file => {
            this.$file = file
          })
          .catch(error => {
            console.error(error)
          })
        return
      }
      delete this.$file
    },
    abrirDoc(doc, e) {
      this.$refs.docApp.show(doc.id)
    }
  }
}
</script>
