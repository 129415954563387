<template>
  <div>
  <list-select async :emit-only-value="onlyValue" ref="select" v-on:input="(v) => $emit('input', v)" :search-data="search"
              :placeholder="placeholder" :search-only-select="autoload" v-model="model" :parse-callback="parse"/>
    <div class="m-t-xs" v-if="model">
      <a @click="$emit('abrirDoc', model && model.id ? model : {id: model})">Pré-visualizar</a>
    </div>
  </div>
</template>

<script>
import ListSelect from "@/reuse/input/ListSelect"
import {listDocumentos} from "@/domain/documento/services"

export default {
  name: "DocumentoSelect",
  props: {
    value: {
      required: true
    },
    code: {
      required: false
    },
    placeholder: {
      default: 'Padrão do sistema'
    },
    autoload: {
      type: Boolean,
      default: false
    },
    onlyValue: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      model: this.value,
      debounce: false,
      lastList: null
    }
  },
  watch: {
    value(v) {
      this.$nextTick(() => {
        if (v !== this.model) {
          this.$refs.select.autoSelectValue(v)
        }
      })
    }
  },
  components: {ListSelect},
  methods: {
    parse (v) {
      return v && v.name ? `${v.categoria.name} - ${v.name}${v.code ? ' ['+ v.code + ']' : ''}` : v
    },
    search(terms) {
      console.log(terms)
      return new Promise((resolve, reject) => {
        const cb = () => {
          const filtros = []
          // if (this.code) filtros.push('codigo-categoria=' + this.code)
          listDocumentos(100, 1,  `&search=${terms || ''}&min=1&` + filtros.join('&'))
              .then(response => {
                console.log(response.data)
                if (!response.data) return []
                let list = response.data.result
                // let list = this.tipo ? response.data.filter(c => Number(c.tipo) === Number(this.tipo)) : response.data
                list = list.map(doc => {
                  return {
                    ...doc,
                    label: `${doc.categoria.name} - ${doc.name} [${doc.code}]`,
                    value: doc.id
                  }
                })
                list.unshift({
                  name: 'Padrão do sistema',
                  label: 'Padrão do sistema',
                  value: null,
                  disabled: true,
                  selectable: true
                })
                resolve(list)
                clearTimeout(this.$debounce)
                this.debounce = false
              })
              .catch(error => {
                this.alertApiError(error)
                reject(error)
                clearTimeout(this.$debounce)
                this.debounce = false
              })
        }
        if (this.debounce){
          clearTimeout(this.$debounce)
        }
        this.$debounce = setTimeout(cb, 600)
        this.debounce = true
      })
    }
  }
}
</script>
